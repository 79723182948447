import React from 'react';
import './About.css';

const About = () => {
    return (
        <section className="about-section">
            <div className="about-container">
                <h2>About Us</h2>
                <p>
                    Welcome to <strong>Earnerswave</strong>, a trusted direct selling platform dedicated 
                    to providing high-quality products while empowering individuals to build their own businesses.
                </p>
                {/* <p>
                    Founded by <strong>Sayog Kushwah</strong>, we operate from home with a mission to create opportunities 
                    for people looking to achieve financial independence through ethical and sustainable direct selling.
                    Our commitment to transparency, trust, and teamwork drives us forward, ensuring a supportive environment 
                    for all our members.
                </p>
                <h3>Founder & Owner</h3>
                <p>
                    <strong>Sayog Kushwah</strong><br/>
                    <strong>Email-- sayogkushwah777@gmail.com</strong><br/>
                </p>
                <h3>Company Address</h3>
                <p>
                    42:- MANTRI NAGAR <br/>
                    BETMA, MADHYA PRADESH, 453001<br/>
                    INDIA
                </p> */}
                <p>
                    At <strong>Earnerswave</strong>, we believe in turning passion into profit. Join us and be part 
                    of a growing community focused on success and shared prosperity!
                </p>
            </div>
        </section>
    );
};

export default About;