import React, { useState } from "react";
import "./offer.css";

const Offer = () => {
  const [language, setLanguage] = useState("english");

  return (
    <div className="offer-container">
      <button onClick={() => setLanguage(language === "english" ? "hindi" : "english")} className="toggle-button">
        {language === "english" ? "हिन्दी में देखें" : "View in English"}
      </button>

      {language === "english" ? (
        <>
          <h1 className="offer-title">🎉 EarnersWave Special Offer 🎉</h1>
          <p className="offer-date">🚀 Effective from March 2025</p>
          <div className="offer-card">
            <h2>💰 New Commission Update</h2>
            <p>Earn ₹100 for every new customer who purchases through your referral link!</p>
          </div>
          <p className="offer-note">📢 Offer applicable only for active users.</p>
      
        </>
      ) : (
        <>
          <h1 className="offer-title">🎉 EarnersWave विशेष ऑफर 🎉</h1>
          <p className="offer-date">🚀 मार्च 2025 से लागू</p>
          <div className="offer-card">
            <h2>💰 नया कमीशन अपडेट</h2>
            <p>हर नए ग्राहक की खरीदारी पर ₹100 कमाएँ, जो आपकी रेफरल लिंक से आता है!</p>
          </div>
          <p className="offer-note">📢 ऑफर केवल सक्रिय उपयोगकर्ताओं के लिए लागू।</p>
          
        </>
      )}
    </div>
  );
};

export default Offer;
