import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FaExclamationTriangle } from 'react-icons/fa'; // Import icon
import "./PanCarddetails.css";

const PanCardUpdate = () => {
  const [panCardNumber, setPanCardNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const validatePanCard = (pan) => /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(pan);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validatePanCard(panCardNumber)) {
      setError("Invalid PAN card format. Please enter a valid PAN card number.");
      return;
    }

    setError("");
    try {
      const token = localStorage.getItem("token"); // Assuming token is stored in localStorage
      const response = await axios.put(
        `${process.env.REACT_APP_PROTOCOL}/api/withdrawal/user-pan-card-update`,
        { panCardNumber, bank_name: bankName, account_number: accountNumber, IFSc_Code: ifscCode },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setMessage("Details updated successfully!");
      navigate("/wallet");
    } catch (err) {
      setError("Failed to update details. Please try again.");
    }
  };

  return (
    <div className="unique-pan-update-container">
      <div className="disclaimer-alert">
        <FaExclamationTriangle className="disclaimer-icon" />
        <h6>
          <strong>Important:</strong> Adding a valid PAN card number is mandatory for withdrawal of amounts greater than ₹13,000.
        </h6>
      </div>
      <form onSubmit={handleSubmit} className="unique-pan-update-form">
        <label htmlFor="panCardNumber">Enter PAN Card Number:</label>
        <input
          type="text"
          id="panCardNumber"
          value={panCardNumber}
          onChange={(e) => setPanCardNumber(e.target.value.toUpperCase())}
          placeholder="ABCDE1234F"
          maxLength="10"
          required
        />
        <label htmlFor="bankName">Bank Name (Optional):</label>
        <input
          type="text"
          id="bankName"
          value={bankName}
          onChange={(e) => setBankName(e.target.value)}
          placeholder="Bank Name"
        />
        <label htmlFor="accountNumber">Account Number (Optional):</label>
        <input
          type="text"
          id="accountNumber"
          value={accountNumber}
          onChange={(e) => setAccountNumber(e.target.value)}
          placeholder="Account Number"
        />
        <label htmlFor="ifscCode">IFSC Code (Optional):</label>
        <input
          type="text"
          id="ifscCode"
          value={ifscCode}
          onChange={(e) => setIfscCode(e.target.value.toUpperCase())}
          placeholder="IFSC Code"
        />
        {error && <p className="unique-pan-update-error-message">{error}</p>}
        {message && <p className="unique-pan-update-success-message">{message}</p>}
        <button type="submit" className="unique-pan-update-button">Update Details</button>
      </form>
    </div>
  );
};

export default PanCardUpdate;
