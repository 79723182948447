import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import './userProfile.css'; // Import your CSS file

function Userprofile() {
  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState(null); // State to hold user data

  // Function to fetch user profile details
  const fetchUserProfile = async () => {
    const token = localStorage.getItem('token'); // Get token from local storage
    if (!token) {
      console.error('No token found. User might not be logged in.');
      return;
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_PROTOCOL}/api/user/user-profile-details`, {
        headers: {
          Authorization: `Bearer ${token}`, // Send token in authorization header
        },
      });

      // Set the user profile data in state
      setUserData(response.data.data);
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  // Fetch user profile on component mount
  useEffect(() => {
    fetchUserProfile(); // Fetch user profile data immediately when the component mounts
  }, []);

  const handleOpenModal = () => {
    setShowModal(true);

    // Push a new history state to detect the back button
    window.history.pushState({ modalOpen: true }, '');
  };

  const handleCloseModal = () => {
    setShowModal(false);

    // Remove the history state when the modal is closed
    if (window.history.state?.modalOpen) {
      window.history.back();
    }
  };

  // Listen for the back button event and close the modal if it's open
  useEffect(() => {
    const handlePopState = () => {
      if (showModal) {
        setShowModal(false);
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [showModal]);

  // If no user data is available, show a loading indicator or a message
  if (!userData) {
    return <div>Loading...</div>; // You can replace this with a spinner or a better loading indicator
  }

  return (
    <Container>
      <Row className="justify-content-center align-items-center">
        <Col md="auto" className="mt-5">
          <div onClick={handleOpenModal} className='profile-img-logo'>
            <img
              src="./Leonardo_Phoenix_give_me_a_user_profile_image_of_a_boy_with_ca_1.jpg" // Placeholder image
              alt="Profile"
              className="profile-logo"
              width={50}
              style={{ cursor: 'pointer' }}
            />
          </div>

          <Modal show={showModal} onHide={handleCloseModal} centered>
            <Modal.Header closeButton>
              <Modal.Title>User Profile</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Card className="profile-card">
                <Card.Body className="p-4">
                  <div className="d-flex profile-info">
                    <div className="flex-shrink-0">
                      <Card.Img
                        className="profile-image"
                        src="./Leonardo_Phoenix_give_me_a_user_profile_image_of_a_boy_with_ca_1.jpg" // Placeholder image
                        alt="Profile"
                        fluid="true"
                        style={{ width: '100px', height: '100px', borderRadius: '50%' }}
                      />
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <p>Name: {userData.first_name}</p>
                      <p>Unique ID: {userData.unique_id}</p>
                      <p>Mobile Number: {userData.phone_number || 'N/A'}</p>
                      <p>Email: {userData.email}</p>
                      <p>Status: {userData.status}</p>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
}

export default Userprofile;
