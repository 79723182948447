import React from 'react';
import { Container, Card, Row, Col, Button } from 'react-bootstrap';
import { Banner, Testimonials, Achievements } from './DashboardEnhancements';
import 'bootstrap/dist/css/bootstrap.min.css';
import './dashboard.css';
import './DashboardEnhancements.css';
import HeaderDashboard from './Headerdashboard';
import Footer from './Footer';

function Dashboard() {
  return (
    <>
      <HeaderDashboard />
      <Container className="dashboard-container">
        {/* Company Logo Section */}
        <div className="text-center mb-4">
          <img src='./earnersWaveLogo.png' alt="Company Logo" className="company-logo" />
          <h1>Welcome to earnerswave.com</h1>
          <p>Your journey to premium quality starts here!</p>
        </div>

        {/* Banner Section */}
        <Banner />

        {/* Kit Section */}
        <div className="kit-details">
          <h2>Our Premium Kit</h2>
          <Row className="justify-content-center">
            {/* Upper Row */}
            <Col md={4}>
              <Card className="kit-product-card">
                <Card.Img variant="top" src="./combo kit 1.jpg" />
                <Card.Body>
                  <Card.Title>Combo Kit</Card.Title>
                  <Card.Title >
                    <span style={{ color: 'green', fontWeight: 'bold' }}>₹1519/-</span>
                  </Card.Title>
                  <Card.Text>Unlock your skin’s true radiance with this comprehensive kit that works synergistically for a flawless complexion. The potent Face Serum brightens and evens out skin tone while reducing dark spots. Paired with a gentle Face Wash that deeply cleanses and reveals natural radiance, the regimen is further enhanced by a lightweight, non-greasy Face Cream providing long-lasting hydration. An exfoliating Gel refines texture and minimizes pigmentation for a smoother appearance, while the non-sticky Body Lotion delivers quick-absorbing moisture, leaving your skin soft and glowing. Together, these products create a complete skincare solution for luminous, nourished, and rejuvenated skin every day.</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="kit-product-card">
                <Card.Img variant="top" src="./Face Serum.jpg" />
                <Card.Body>
                  <Card.Title>Gleam & Glam Face Serum</Card.Title>
                  <Card.Title >
                    <span style={{ color: 'green', fontWeight: 'bold' ,width:"10px" ,height:"10px"}}>₹549/-</span>
                  </Card.Title>
                  <Card.Text>Key Features:
                    Gleam and Glam Serum is designed to brighten and even out your skin tone. This lightweight serum penetrates deeply to reduce dark spots and pigmentation, leaving your complexion radiant and flawless. It provides an instant glow while deeply nourishing the skin, making it perfect for daily use.

                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="kit-product-card">
                <Card.Img variant="top" src="./Face Wash.jpg" />
                <Card.Body>
                  <Card.Title>Gleam & Glam Face Wash</Card.Title>
                  <Card.Title >
                    <span style={{ color: 'green', fontWeight: 'bold' , width:'100px'}}>₹269/-</span>
                  </Card.Title>
                  <Card.Text>Key Features:
                  Experience brighter, clearer skin with this advanced face wash, designed to reveal your skin's true radiance. It gently exfoliates and removes impurities, promoting a fresh and luminous complexion. Discover the perfect addition to your skincare routine and bring this innovation home today!
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className="justify-content-center">
            {/* Lower Row */}
            <Col md={4}>
              <Card className="kit-product-card">
                <Card.Img variant="top" src="./Face Cream 1.jpg" />
                <Card.Body>
                  <Card.Title>Gleam & Glam Face Cream</Card.Title>
                  <Card.Title >
                    <span style={{ color: 'green', fontWeight: 'bold' }}>₹245/-</span>
                  </Card.Title>
                  <Card.Text>Key Features:
                  Gleam and Glam Face Cream is a lightweight and non-greasy formula that is designed to enhance your skin's natural glow. this cream provides long-lasting hydration and helps maintain a healthy, dewy complexion. The gentle and fast-absorbing cream helps to nourish the skin without leaving a greasy residue. Ideal for daily use, it rejuvenates your skin, giving it a fresh and youthful appearance. perfect for daily skincare routines
                    .</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="kit-product-card">
                <Card.Img variant="top" src="./Face Gel.jpg" />
                <Card.Body>
                  <Card.Title>Gleam & Glam Gel</Card.Title>
                  <Card.Title >
                    <span style={{ color: 'green', fontWeight: 'bold' }}>₹197/-</span>
                  </Card.Title>
                  <Card.Text>Key Features:Experience a comprehensive skin transformation with Gleam and Glam Gel. This gel effectively addresses dark spots, pigmentation issues, and promotes an even skin tone for a brighter complexion. It gently sheds dead skin cells, encouraging cellular regeneration and unveiling a brighter, smoother
                  complexion. This exfoliation process not only rejuvenates the skin's surface but also effectively reduces hyperpigmentation and fades dark spots, promoting a more even skin tone.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="kit-product-card">
                <Card.Img variant="top" src="./Body Lotion.jpg" />
                <Card.Body>
                  <Card.Title>Gleam & Glam Body Loation</Card.Title>
                  <Card.Title >
                    <span style={{ color: 'green', fontWeight: 'bold' }}>₹259/-</span>
                  </Card.Title>
                  <Card.Text>Key Features: Gleam and Glam glam Body Lotion is a lightweight, non-sticky, and quick-absorbing lotion that leaves your skin ready to glow. It’s the perfect combination for the Indian climate and suitable for all seasons.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>

        {/* MLM Overview Section */}
        <div className="mlm-overview text-center mb-4">
          <h2 className="mlm-title">Unlock Your Potential with Our Earners Program</h2>
          <p className="mlm-description">Shop, Sell, Succeed: Turn Every Purchase into Profit</p>
          <Row>
            <Col md={4} className="mb-4">
              <i className="mlm-benefit-icon fas fa-users"></i>
              <h4>Build Your Team</h4>
              <p>Invite friends and family to join, and watch your team grow!</p>
            </Col>
            <Col md={4} className="mb-4">
              <i className="mlm-benefit-icon fas fa-coins"></i>
              <h4>Earn Commission</h4>
              <p>Receive commissions for every sale made by your referrals.</p>
            </Col>
            <Col md={4} className="mb-4">
              <i className="mlm-benefit-icon fas fa-trophy"></i>
              <h4>Achieve Rewards</h4>
              <p>Unlock exclusive bonuses and rewards as you hit milestones.</p>
            </Col>
          </Row>
          <Button variant="success" className="get-started-button" href='/signup'>Get Started</Button>
        </div>

        {/* Income Structure Section */}
        <div className="income-structure text-center mb-4">
          <h2>Your Income Structure</h2>
          <p>Explore how our structured income program can enhance your earnings!</p>
          <Row>
            <Col md={6} className="income-level">
              <h4>Level 1: Direct Referrals</h4>
              <p>Earn a bonus for each direct referral.</p>
            </Col>
            <Col md={6} className="income-level">
              <h4>Level 2: Team Earnings</h4>
              <p>Receive a percentage from team members' sales.</p>
            </Col>
          </Row>
          <Button variant="info" className="learn-more-button" href='/signup'>Learn More</Button>
        </div>

        {/* Testimonials and Achievements */}
        <Testimonials />
        <Achievements />
      </Container>
      <Footer />
    </>
  );
}

export default Dashboard;