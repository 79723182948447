import React from 'react';
import './EmailButton.css'; // Import the CSS file
import { FaEnvelope } from 'react-icons/fa'; // Import a contact-like icon

const EmailButton = () => {
  const email = 'suport.earnerswave@gmail.com'; // Your email address
  const subject = 'Inquiry from Website'; // Pre-filled subject
  
  // Format the email link
  const emailLink = `mailto:${email}?subject=${encodeURIComponent(subject)}`;

  return (
    <div className="email-button-container">
      <a href={emailLink} className="email-button-link">
        <button className="email-button">
          <FaEnvelope className="email-icon" />
          Support
        </button>
      </a>
    </div>
  );
};

export default EmailButton;
